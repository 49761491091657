import React from 'react';
import { Center, Grid, GridItem, Progress, Text } from '@chakra-ui/react';
import { Loading } from '../Icons';
import { sizes } from '../../theme';

const propTypes = {};
const defaultProps = {};

const LoadingScreen = () => (
  <Center h="500px" w="100%" bg="white">
    <Grid>
      <GridItem align="center">
        <Loading w="288" h={{ base: '60px', md: '67px' }} />
      </GridItem>
      <GridItem align="center" marginTop={sizes._20px}>
        <Progress
          isIndeterminate
          colorScheme="progressBar"
          w={{ base: '280px', md: '344px' }}
          p={{ base: '0 6rem', md: '0px' }}
          size="sm"
        />
      </GridItem>
      <GridItem align="center" marginTop={sizes._20px}>
        <Text variant="xlBold">
          Hang Tight!
        </Text>
      </GridItem>
      <GridItem
        align="center"
        marginTop={sizes._12px}
        w="344px"
        p={{ base: '0 2rem', md: '0px' }}
      >
        <Text variant="md">
          {`We're processing your rental. Once complete you'll be
          redirected to create an account with the storage facility.`}
        </Text>
      </GridItem>
    </Grid>
  </Center>
);

LoadingScreen.propTypes = propTypes;
LoadingScreen.defaultProps = defaultProps;

export default LoadingScreen;
