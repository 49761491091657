import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import { sizes } from '../../theme';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
const defaultProps = {};
const Terms = ({ isOpen, onClose, onSubmit }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <div className="OMI_CSS_CONTAINER">
    <ModalContent>
      <ModalHeader>Terms and Conditions</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {`By submitting your credit card information you are confirming your desire to rent
          the unit set forth on this confirmation page and authorizing your credit card to be
          charged “Today's Total Charges” as detailed above. To gain access to your rented unit,
          please review and sign the lease that is presented to you in the next step. Only
          proceed with making this payment if your intent is to rent the storage unit. If,
          prior to signing your lease you no longer wish to proceed with your rental, please
          contact the storage facility to discuss next steps. Recurring monthly charges and/or
          refunds are unique to each facility.`}
      </ModalBody>
      <ModalFooter sx={{ p: `${sizes._16px} !important`, boxSizing: 'border-box' }}>
        <Button onClick={onClose} flexGrow="1" variant="inaction" w="unset">Cancel</Button>
        <Button onClick={onSubmit} flexGrow="1" variant="action" w="unset">Agree & Submit Payment</Button>
      </ModalFooter>
    </ModalContent>
    </div>
  </Modal>
);
Terms.propTypes = propTypes;
Terms.defaultProps = defaultProps;
export default Terms;
