const values = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  moveInDate: '',
  mailing: {
    firstName: '',
    lastName: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zipCode: '',
  },
  billing: {
    firstName: '',
    lastName: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    state: '',
    zipCode: '',
  },
  saveCreditCard: true,
  enrollAutoPay: true,
  ccNumber: '',
  ccExp: '',
  ccCSC: '',
  militaryStatus: '',
  driversLicense: {
    number: '',
    state: '',
    expirationDate: '',
    birthDate: '',
  },
  vehicles: [{
    description: '',
    make: '',
    model: '',
    VIN: '',
    state: '',
    license: '',
  }],
  contacts: [{
    firstName: '',
    lastName: '',
    relationship: '',
    phone: '',
  }],
  lienholders: [{
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
  }],
  password: '',
  confirmPassword: '',
  securityQuestionsRequired: false,
  securityQuestionOne: '',
  securityQuestionTwo: '',
  securityAnswerOne: '',
  securityAnswerTwo: '',
  insurance: '',
  lock: '',
  dlImage: [],
  confirmPrivateInsurance: false,
};

export default values;
