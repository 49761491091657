import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';

const SummaryRow = ({ children, hasBottomBorder = true, padRow }) => (
  <Flex
    direction="row"
    justifyContent="space-between"
    borderBottom={hasBottomBorder ? '6px solid' : ''}
    borderColor={hasBottomBorder ? 'gray.lightBorder' : ''}
    px={padRow ? '16px' : ''}
  >
    <Grid templateColumns="repeat(2, 1fr)" w="100%">
      {children}
    </Grid>
  </Flex>
);

export default SummaryRow;
