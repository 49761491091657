import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormRadio from '../FormRadio';
import FormMulti from '../FormMulti';
import VehicleForm from '../VehicleForm';
import { makeFieldsRequired } from '../MoveIn/reducer';

const propTypes = {};
const defaultProps = {};

const Vehicles = ({ step }) => {
  const { control, setValue, watch } = useFormContext();
  const hasVehicles = useWatch({ control, name: 'hasVehicle' }) === 'yes';
  const vehicles = watch('vehicles'); // Watch vehicles array
  // Dynamically manage required fields
  useEffect(() => {
    makeFieldsRequired({
      stepModifiers: { hasVehicle: hasVehicles },
      step,
      formState: { vehicles }, // Pass the vehicles array directly
    });
  }, [vehicles, hasVehicles, step]);

  // Initialize at least one vehicle in the vehicles array when hasVehicles is true
  useEffect(() => {
    if (hasVehicles && (!vehicles || vehicles.length === 0)) {
      setValue('vehicles', [{}]); // Add an empty object as the initial vehicle
    }
  }, [hasVehicles, setValue, vehicles]);

  return (
    <>
      <FormRadio
        name="hasVehicle"
        options={[{ value: 'yes', description: 'Yes' }, { value: 'no', description: 'No' }]}
        subHeader="Add an automobile, truck, motorcycle, SUV, boat, RV, 4-wheeler, jet ski, or snowmobille stored in the unit."
        title="Are You Storing A Vehicle?"
      />
      {hasVehicles ? (
        <FormMulti
          descriptor="vehicle"
          name="vehicles"
          title="Stored Vehicle"
        >
          <VehicleForm hasVehicles={hasVehicles} />
        </FormMulti>
      ) : null}
    </>
  );
};

Vehicles.propTypes = propTypes;
Vehicles.defaultProps = defaultProps;

export default Vehicles;
