export const insurances = [
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWE',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: '106S',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: '422S',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: '4STB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STD 4',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: '4STP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ABCD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ABCP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ALB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ALLP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'AMBV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'AMDS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'AMPV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ARC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ATRF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ATS 1.00',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ATS 2.00',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ATS 3.00',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ATSH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BAYS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BBFM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BFSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BGAT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BGBR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BLSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BOW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BROO',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'BSSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CAKS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CAPF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CATW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CBCL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CBNT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CBSN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CCNM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CCRH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CCVE',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CDTP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CEBK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CEVG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CFRM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CFSA',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CGGK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CGTG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CGTK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHBF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHBM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHVH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHVN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHVW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CHWH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CI49',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CIPS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CJDG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CJDG1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CJMI',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CJNM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CKOP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CL22',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLAA',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLBM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLCT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLDN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLEP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLFF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLFW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLHB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLHP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLHT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLHV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLIV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLJB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLLL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLLM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLLS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLMD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLOP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLPT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLRF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLRH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLRL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLRO',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLRV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLSB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLTP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLTS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLTT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLVB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLWM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLWN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLWS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLXT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CLYK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMKC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMKM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMMM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMPM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMTG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CMTL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CNAS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COC4',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COCJ',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COCR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COK1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COKS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'COOL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CPSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CPTP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CPX4',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CPXW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CRTG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CSCR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CSPT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CSVG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CUAM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CWRG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'CWSG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'DSLR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'DSPH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'DV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'EC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ELLI',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'FAIR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'FISS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'HERN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'HSS1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'HSS2',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'HSS3',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ICMN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISDP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISDP1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISLB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISPP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSG1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ISSP1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'JBSF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'KMTP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'KSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'LION',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'LLAB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'LPSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MCBP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MECH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MMTF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MSGD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MSRN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MSST',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'MUSU',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'OSCP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'OSLA',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'OSLC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'OSNH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'OSYT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'PMGN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'PR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ROS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SAFE',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SAL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SCTM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SDOC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SDOD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SDOS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SENC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SESS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SGSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SIHM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SMIS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SMOR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SP30',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SPRM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SS022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SS023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSAV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSCT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSEC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSEN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSEO',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSFM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSFS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSGV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSHD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSKY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSMB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSMN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSMT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSNF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSPB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSPV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSWB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSWF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SSWY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STAW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STIR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STM1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STM2',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STM3',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STM4',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STSM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STVN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'STWD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SUCI',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'SUNS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'TNCS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'TR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VACT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VAMT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VAUN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSAK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSAS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSDB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSDV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSER',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSGW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSGW1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSHB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSIW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSLB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSLO',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSLX',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSMB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSNC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSNR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSRW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSSN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSSQ',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSST',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSTS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSVM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSWH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VSWM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VUAB',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VUHM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'VUTR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'WK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC1',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC1A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC1B',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC1C',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC2',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC2A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC2B',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC3',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC3A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC3B',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC4',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC4A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC5',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC6',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC6A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'YEC6B',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJAJ',
    locCode: 'ZIAS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHUT',
    locCode: 'L001',
  },
  {
    name: 'Storage Insurance',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'EDGE',
    locCode: 'L001',
  },
  {
    name: 'Storage Insurance',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'EDGE',
    locCode: 'L002',
  },
  {
    name: 'Self Storage Contents Insurance',
    description:
      'Self Storage Contents Insurance is provided by Property First Group, LP, a licensed insurance agency.  To view an informational brochure, visit https://bit.ly/pfgnr.  The purchase of this insurance is not required to complete your rental transaction.',
    corpCode: 'EDGE',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'DOW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'FIL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'LAG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'LAN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'LYN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'MH',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'PAL01',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'SG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLAP',
    locCode: 'SYL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPCT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSKG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSKG',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSZJ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Please note that insurance is required at this location. You will be asked for proof of insurance upon move in so please only opt out of insurance if you already have purchased insurance from a third party. Thank you for your cooperation in this matter!',
    corpCode: 'CCMQ',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L027',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L032',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L033',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L034',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L035',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L036',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L037',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L038',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L039',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L040',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L041',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L042',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L043',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L044',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L045',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L046',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L047',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L048',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L049',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L050',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L051',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L052',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L053',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L054',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L055',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L057',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L058',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L059',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L060',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L061',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L062',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L063',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L064',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L065',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L066',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L067',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L071',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L072',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L073',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L074',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L077',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L078',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L079',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L080',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L081',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L082',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L083',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L084',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L085',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L086',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L087',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L088',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L089',
  },
  {
    name: 'Protection Plan',
    description:
      'Please note that insurance is mandatory at this location. You will be asked for proof of insurance upon check in and if you are unable to provide such proof, we will require you to purchase insurance. If you have already purchased from a third party, then please kindly proceed without purchasing insurance. We thank you for your cooperation in this matter.',
    corpCode: 'CCMQ',
    locCode: 'L090',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L091',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L093',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L094',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L095',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L096',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L097',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L098',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L099',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L100',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L101',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L102',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L103',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L104',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L105',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L106',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L107',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L108',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L109',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L110',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L111',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L112',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L113',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L114',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L115',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L116',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L117',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L118',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L119',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L120',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L121',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L122',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L123',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L124',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L125',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L126',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L128',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L129',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L130',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L131',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L132',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L133',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L134',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L135',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L136',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L137',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L138',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L139',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L140',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L141',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L142',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L143',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L145',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L146',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L147',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L148',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L149',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L150',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L151',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L152',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L153',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L154',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L155',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L156',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L157',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L158',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L159',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L160',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L161',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L162',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L163',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L164',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L165',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L166',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L167',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L172',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L176',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L195',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L208',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L209',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L213',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L225',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L233',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L239',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L244',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L245',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L256',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L260',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L262',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L263',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L268',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L269',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L276',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L277',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L280',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L284',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L289',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L290',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L292',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L296',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMQ',
    locCode: 'L298',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L299',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L300',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L302',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L303',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L307',
  },
  {
    name: 'Protection Plan',
    description:
      "Please note that insurance is mandatory at this location. If you opt out of insurance, you will be asked for proof of insurance upon check-in. Kindly only opt out of insurance if you already have purchased renter's insurance from a third party. Your cooperation and understanding in this matter is greatly appreciated.",
    corpCode: 'CCMQ',
    locCode: 'L321',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPZN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPZN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPZN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPZN',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQAA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQAA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C374',
    locCode: 'L117',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C374',
    locCode: 'L118',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CZVJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEME',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDRY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEU',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEU',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Coverage is required when storing with us.  We highly recommend that all tenants insure their goods for peace of mind with our Property Protection Plan.',
    corpCode: 'CBCN',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L026',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L027',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L033',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L034',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L035',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L036',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L037',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L038',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L039',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L040',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L041',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L042',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L043',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L044',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L045',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L046',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L047',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L048',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L049',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L050',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L051',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'L052',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCN',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRBS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSPN',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDKV',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZTF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPJR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPJR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHSG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCYN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCYN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCYN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPUU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPUU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CESW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBMS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'CALL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSALES',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CESL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C371',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C371',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CECQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CECQ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHTJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNGW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNGW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNGW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Mini Mall Storage Properties requires all tenants to carry insurance. Mini Mall does provide a protection plan or you can substitute this plan with your own insurance. Please contact the store should you have any questions.',
    corpCode: 'CEFP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L050',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L051',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L052',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L053',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L054',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L055',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L056',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L057',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L100',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L101',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L151',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L152',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L153',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L201',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L301',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L302',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L304',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L350',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L351',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L352',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L353',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L354',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L401',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L402',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L403',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L450',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L451',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L452',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L453',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L454',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L455',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L456',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L457',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L458',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L459',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L460',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L461',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L462',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L463',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L464',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L465',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L466',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L467',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L601',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L602',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L603',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFP',
    locCode: 'L604',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSSC',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRN',
    locCode: 'L047',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRN',
    locCode: 'L055',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRN',
    locCode: 'L058',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRN',
    locCode: 'L061',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRN',
    locCode: 'L065',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAQE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCL',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCL',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C208',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C208',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPGG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBJY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPUD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZAR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZAR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CREN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCNB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CHSY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CHSY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CHSY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSCY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CANK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWJ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. Insurance is required and you can purchase insurance through us at a minimum of $10 per month. If you have your own insurance, please give us this information before move in.',
    corpCode: 'CQWJ',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'HAV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'MAY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'NOR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'OLN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'PHI',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'RIV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCCC',
    locCode: 'ROX',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAEN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAEN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAEN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRBM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEET',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEET',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEET',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEET',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDKH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDKH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDKH',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQCY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQCY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDVZ',
    locCode: 'L044',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CXAZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CXAZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CXAZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSUF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSUF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSUF',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRXF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYG',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGO',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGO',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWJ',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description: 'Insurance is not included with our rent charges.',
    corpCode: 'CEMN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXS',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXQ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXQ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXQ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'We highly recommend that all tenants insure their goods for peace of mind. We have included it for your move in, if you would like to use your own please provide it to the office and we will remove the charge for the next rental period. Thank you!',
    corpCode: 'CNXQ',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCLG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Urban Heights provides requires Tenant Protection Coverage of $5,000 for $9 per month.   Customer may opt out with proof of homeowners or business coverage.',
    corpCode: 'CZQC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: '$10,000.00 Insurance Coverage Included!',
    corpCode: 'CEPC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require tenants to get insurance to add an extra layer of security for the duration of their stay at our facility.',
    corpCode: 'C846',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We ask that all tenants insure their goods for peace of mind.',
    corpCode: 'C846',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      "Insurance is not included with our rent charges. We require tenant's to have insurance while storing with us unless self storage is covered by your homeowner's or renter's insurance policy.",
    corpCode: 'C846',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'C846',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require tenants to get insurance to add an extra layer of security for the duration of their stay at our facility.',
    corpCode: 'C846',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'C846',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C965',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPKP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPGA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYK',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYK',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYYK',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZPC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L061',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L074',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L085',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L092',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L093',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L154',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with your rental and is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  If you already have coverage, for instance through your renters or home owners policy, you can bring in proof of insurance and use that policy in future months.',
    corpCode: 'CRAF',
    locCode: 'L155',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRAF',
    locCode: 'L156',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRAF',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: 'Insurance is not included with our rent charges.',
    corpCode: 'CGDB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: 'Insurance is not included with our rent charges.',
    corpCode: 'CGDB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'BLIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Online Rentals at Signature Storage require participation in the Rental Protection Plan. Please provide your Homeowners declaration page within 5 business days if you choose to opt-out of our Protection Plan.',
    corpCode: 'CZSB',
    locCode: 'CDIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'EVMO',
  },
  {
    name: 'Protection Plan',
    description:
      'Online Rentals at Signature Storage require participation in the Rental Protection Plan. Please provide your Homeowners declaration page within 5 business days if you choose to opt-out of our Protection Plan.',
    corpCode: 'CZSB',
    locCode: 'MRIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'NRIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Online Rentals at Signature Storage require participation in the Rental Protection Plan. Please provide your Homeowners declaration page within 5 business days if you choose to opt-out of our Protection Plan.',
    corpCode: 'CZSB',
    locCode: 'OLIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'PPIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'TRIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSB',
    locCode: 'WNMO',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at our storage facility. If you currently have insurance, you must select a coverage now which will be canceled when proof of insurance by another insurer is submitted to Carroll’s Self Storage. Otherwise, the plan you select will be your coverage.',
    corpCode: 'CEFI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAZ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPR',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPP',
    locCode: 'L100',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBCQ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZEY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCSD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSVF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENK',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind. If you have storage insurance through your homeowners insurance, please bring us a copy of your policy or call to give us that information.',
    corpCode: 'CEPV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBJM',
    locCode: 'LAMP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. All tenants must have insurance coverage for their goods prior to storing.',
    corpCode: 'CQWW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQWW',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPQA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPQA',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCWX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCT',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCT',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCT',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCT',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZKB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. We highly recommend if you have a Homeowners or Renters Insurance Policy that covers a storage unit, to email a copy to our facility so you can opt-out of our coverage.',
    corpCode: 'CECI',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L026',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L027',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L032',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L033',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L034',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L035',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L036',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L037',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L038',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L039',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L040',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L041',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L042',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L043',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L044',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L045',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L046',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L047',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L048',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L049',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L050',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L051',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L101',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L102',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDX',
    locCode: 'L103',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTEW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSKC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C613',
    locCode: 'L045',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHSJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHTN',
    locCode: 'CAY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPJ',
    locCode: 'POINT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.  Please provide proof of insurance or buy a policy here online.',
    corpCode: 'CHPG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYXD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYXD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYXD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYXD',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYXD',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYDA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants select an insurance plan before booking. If you wish to provide alternate coverage, you can do so after booking has been confirmed by calling or visiting the facilities office.',
    corpCode: 'CQZM',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZM',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPVC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXD',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L026',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L032',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L033',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L034',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L035',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L036',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L037',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAC',
    locCode: 'L038',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CACN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require proof of property insurance for all self-storage rentals.  BestWay2Store offers very affordable insurance that can be applied to your monthly rental rate.',
    corpCode: 'CETN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRBG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      "Insurance is required, but no included with our rent charges. Please click the '+' button to select your insurance. If you want to discuss using homeowner's insurance instead, please contact the property manager after your move-in.",
    corpCode: 'CGDN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCWU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNQZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYE',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYE',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZYE',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAGF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAGF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAGF',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CECF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENW',
    locCode: 'L002',
  },
  {
    name: 'Stored Contents Insurance',
    description:
      'Self Storage Contents Insurance is provided by Property First Group, LP, a licensed insurance agency.  To view an informational brochure, visit https://bit.ly/pfgnr.  The purchase of this insurance is not required to complete your rental transaction.',
    corpCode: 'CEMG',
    locCode: 'L001',
  },
  {
    name: 'Stored Contents Insurance',
    description:
      'Self Storage Contents Insurance is provided by Property First Group, LP, a licensed insurance agency.  To view an informational brochure, visit https://bit.ly/pfgnr.  The purchase of this insurance is not required to complete your rental transaction.',
    corpCode: 'CEMG',
    locCode: 'L002',
  },
  {
    name: 'Stored Contents Insurance',
    description:
      'Self Storage Contents Insurance is provided by Property First Group, LP, a licensed insurance agency.  To view an informational brochure, visit https://bit.ly/pfgnr.  The purchase of this insurance is not required to complete your rental transaction.',
    corpCode: 'CEMG',
    locCode: 'L003',
  },
  {
    name: 'Stored Contents Insurance',
    description:
      'Self Storage Contents Insurance is provided by Property First Group, LP, a licensed insurance agency.  To view an informational brochure, visit https://bit.ly/pfgnr.  The purchase of this insurance is not required to complete your rental transaction.',
    corpCode: 'CEMG',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for your storage needs.',
    corpCode: 'CEMG',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required at this location. Please select the protection plan that works best for you.',
    corpCode: 'CEMG',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L026',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L027',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEMG',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQZH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETY',
    locCode: 'L001',
  },
  {
    name: 'Bader',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDFN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCGZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCGZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZFM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNM',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNER',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNER',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNER',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNER',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSFJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSFJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSFJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is mandatory and is not included with our rent charges. Please select a coverage amount. If you provide proof of insurance for your stored goods, this charge will be credited back to you.',
    corpCode: 'C751',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is mandatory and is not included with our rent charges. Please select a coverage amount. If you provide proof of insurance for your stored goods, this charge will be credited back to you.',
    corpCode: 'C751',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYM',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRYR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRYR',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRYR',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZHZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTEZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQHC',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHXU',
    locCode: 'L00A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHXU',
    locCode: 'L00B',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHXU',
    locCode: 'L00C',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHXU',
    locCode: 'L00F',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTFW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTFW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTFW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTFW',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPSW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $2500.00 coverage for an additional $6.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'I acknowledge that I am required to insure my personal property while it is on the premises. I will provide a copy of the declaration page of my insurance policy within 30 days or Attic Storage will add the $1500.00 coverage for an additional $9.00 per month added to my rent.',
    corpCode: 'CEBX',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGH',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYF',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYF',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDCB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDCB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBBF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHVB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNVX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPQC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZRK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRDF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRDF',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPQU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYBE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRFN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C626',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEDI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNDS',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNDS',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C294',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWAZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTHV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTHV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTHV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQBC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CALC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'We require all of our tenants to carry insurance on their goods.  Please select an insurance policy that we provide.  You can provide proof of insurance at a later date, via email, to have that policy cancelled.',
    corpCode: 'CSCZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Proof of insurance or purchase of Tenant Protection is required with rental.',
    corpCode: 'CTBQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSMV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCBN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZXC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZXC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAJN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCBU',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCBU',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCBU',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCBU',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVT',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQM',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWM',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWM',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C105',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXH',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXH',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXH',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXH',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQXE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQUW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C714',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWQ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPE',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CPTY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CPTY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CPTY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CPTY',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPTY',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCT',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQRX',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEBC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEBC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEDG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPTZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CASG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CASG',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CASG',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CASG',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCS',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEVR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJCV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEXB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFBE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFCC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPTS',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPTS',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDEG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEEX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: '145TH',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'ARCIS',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'ATLA',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'BUSH',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'CLINT',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'COOP',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'HOLL',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'OZONE',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'PATER',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'PENN',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'REDH',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'REMS',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'RIVER',
  },
  {
    name: 'Protection Plan',
    description:
      'The StorSafe Protection Plan is provided for your benefit and Peace of Mind. The plan is a convenient and affordable option designed to protect your belongings while in storage. This is required to complete the rental process. Please use the plus sign below to select your plan.',
    corpCode: 'CZCU',
    locCode: 'WOOD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFCE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFBC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYJ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDCM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      "Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.  All tenants agree to and are required to maintain some storage and/or renter's insurance.",
    corpCode: 'CXAK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      "Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.  All tenants are required to maintain some type of storage and/or renter's insurance per agreement in lease.",
    corpCode: 'CXAK',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZJF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZJF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C795',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is mandatory and is not included in the unit price.',
    corpCode: 'CQEX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C273',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'We do not offer insurance at this time, coverage is optional although we highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZBT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. \n\nInsurance is required to protect your cherished belongings, but not provided for vehicles, boats, RVs or belongings with an engine.',
    corpCode: 'CFDB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWU',
    locCode: 'L001',
  },
  {
    name: 'Safestor Tenant Insurance',
    description:
      'Click the “”+”" or “”-"" to select the right insurance plan for you. Please note that insurance is NOT INCLUDED with the selected unit\'s rent charges',
    corpCode: 'CEWR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQSG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'DEMO',
    locCode: 'DEMO5',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZPF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAYY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEFW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNF',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNF',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZJB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZJB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPWC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQPR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQPR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQPR',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQPR',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQPR',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C938',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C323',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C323',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDML',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFEJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNSB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFEH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C615',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C256',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C615',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C615',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZRA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C256',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C683',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C973',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBL',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAM',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAM',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAM',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAM',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRL',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFN',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C818',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C818',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C818',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C818',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAUW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBDU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZTD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L026',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L032',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDV',
    locCode: 'L033',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C857',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C857',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C256',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require that all tenants insure their goods for peace of mind.',
    corpCode: 'CEXI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CARB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CARB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFCU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFCU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C165',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPZY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAFF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNKF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNKF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFR',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFR',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So, when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So, when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So, when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So, when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      "We understand how important your belongings are. So, when you choose to utilize self-storage, you should rest easy knowing that your things are protected. That's why we offer 3 Tenant Insurance options! On a daily basis, you'll enjoy peace of mind, and in case of an emergency, your insurance is in place to ease the blow.",
    corpCode: 'C363',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNHK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDSP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'USG',
    locCode: 'ALL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'USG',
    locCode: 'KEY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTAD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTAD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require all tenants insure their goods for peace of mind.',
    corpCode: 'CTAD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We require all tenants insure their goods for peace of mind.',
    corpCode: 'CTAD',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTAD',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZBR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our storage charges. We strongly recommend that all customers insure their goods for peace of mind. Please contact us at 403.252.2552 for more information about insurance options available to you.',
    corpCode: 'CQBT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our storage charges. We strongly recommend that all customers insure their goods for peace of mind. Please contact us at 403.252.2552 for more information about insurance options available to you.',
    corpCode: 'CQBT',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our storage charges. We strongly recommend that all customers insure their goods for peace of mind. Please contact us at 403.252.2552 for more information about insurance options available to you.',
    corpCode: 'CQBT',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind. Contact us if you wish to arrange insurance.',
    corpCode: 'CQBT',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZQV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFEZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFEZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHRL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDSQ',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXF',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXF',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXF',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXF',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCXF',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZLL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFGJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPER',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKQ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKQ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKQ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CASI',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCO',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGCO',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZND',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDFY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEWX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZPK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZPK',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQNW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAME',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZZW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZZW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEJK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDWX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSDR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYCD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBUR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBUR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHQM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNXD',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUP',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUP',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUP',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYBZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYBZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDGX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CXZX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Our Tenant Protection Plan allows you to store your belongings with confidence knowing that you will be reimbursed for the cost of replacement of your damaged or stolen items, up to the dollar amount you choose on your plan. There are no deductibles, and protection plan fees are conveniently collected along with your monthly rent.',
    corpCode: 'CNSV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBZH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBZH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRP',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNRX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNRX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBNL',
    locCode: 'L053',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFBA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSWA',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSRB',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHAL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGDA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDSY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDSY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDEU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTHR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCAY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCAY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCAY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZRZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEQU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNMZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDHM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQDB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAVJ',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAK',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNAK',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNBW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNBW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTGF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C861',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C861',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C861',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C861',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPMJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. Insurance is mandatory.  You can opt out and provide your own insurance. If you opt out of insurance you must provide proof of insurance within 7 days or it will be automatically added to you account.',
    corpCode: 'CFLN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C155',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C633',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CFRV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CFRV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CFRV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description: '',
    corpCode: 'CFRV',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C319',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C591',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CERH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPCD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges but is required.  Tenant may purchase Bader/StorSmart tenant insurance though us.  Otherwise, proof of insurance is required.',
    corpCode: 'CFKF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges but is required.  Tenant may purchase Bader/StorSmart tenant insurance though us.  Otherwise, proof of insurance is required.',
    corpCode: 'CFKF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRWV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDWF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFHY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCBP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCBV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCCM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCCS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCDT',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCDW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCES',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCEW',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCGA',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCLP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCMS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCNP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCNS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCRS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCSK',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCTY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCVS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCWC',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCWP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDXS',
    locCode: 'LCWV',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQDY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDMK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFME',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFLM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDEH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDEH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDEH',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSJD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSJD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C679',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCJS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCJS',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CETH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C521',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C521',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C279',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C279',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPMD',
    locCode: 'L680',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMD',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBUK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBRN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTEE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEXY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSJH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'INSURANCE IS REQUIRED FOR ALL TENANTS. You can use your homeowners or renters policy, if your policy covers the contents of your storage unit.  To use your policy, please select the $10 policy now, then email your proof of coverage to Storage@SCHProperties.org within 14 days of your rental.  Once coverage is verified, we will remove the $10 Policy.  If we do not receive your proof of coverage within 14 days of your rental, our basic policy will remain on your account.',
    corpCode: 'CFNB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'INSURANCE IS REQUIRED FOR ALL TENANTS. You can use your homeowners or renters policy, if your policy covers the contents of your storage unit.  To use your policy, please select the $10 policy now, then email your proof of coverage to Storage@SCHProperties.org within 14 days of your rental.  Once coverage is verified, we will remove the $10 Policy.  If we do not receive your proof of coverage within 14 days of your rental, our basic policy will remain on your account.',
    corpCode: 'CFNB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFNB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRDZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPEY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPEY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSNJ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C281',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C281',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C281',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C281',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C281',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJDK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZHH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBNZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CABV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CABV',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CABV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CABV',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPF',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPF',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPF',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCPF',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAEW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'You may opt-out of protection if you provide the office with proof of insurance',
    corpCode: 'CFML',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAYT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHNY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJET',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C964',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CLRB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCHX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCHX',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSS',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRVX',
    locCode: 'CUBE',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRVX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFPD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFEU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNYN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required for every lease. If you have a homeowners insurance policy that you can provide in person, our insurance will not be required.',
    corpCode: 'CFSE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSE',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required for every lease. If you have a homeowners insurance policy that you can provide in person, our insurance will not be required.',
    corpCode: 'CFSE',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSE',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLH',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required. Please choose from our list of personal coverages.\n\nPlease note, rent prices are based on a 28-day billing cycle!',
    corpCode: 'CBUD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSX',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKZ',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSQN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFPF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFLQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFMW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRWK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYEB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using their home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L013',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L019',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L021',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L022',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind using home owners or renters insurance.',
    corpCode: 'CYWP',
    locCode: 'L023',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYWP',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTL',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTL',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEDC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTBK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTCB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPDA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEPI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQI',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFAA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFJY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C549',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'FAYLLC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQDX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQDX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAPY',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAPY',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAPY',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAPY',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAPY',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBXN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAA',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAA',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTW',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTW',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFPX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZZV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDVL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDVL',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZLA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZLA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFTY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHZS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWB',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFPY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGE',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQUQ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C557',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJGF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQY',
    locCode: 'L001',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L005',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L008',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L010',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L011',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L013',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L014',
  },
  {
    name: 'Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CMAU',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJEY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is mandatory, either use our insurance below or provide proof of your own insurance.',
    corpCode: 'C504',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNHZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C682',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C682',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C682',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZVV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZSU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBLC',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFNI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATX',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATX',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATX',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRYZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDRP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHLH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFPI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPJG',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPJG',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPJG',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFDD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBYA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBYA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBYA',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZXB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSGJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZHR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCEN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CESP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CATQ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFSN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance purchase is required unless alternate proof of coverage is provided at move-in. Without proof of alternate coverage, a $9 monthly insurance premium will be added.',
    corpCode: 'CAKP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance purchase is required unless alternate proof of coverage is provided at move-in. Without proof of alternate coverage, a $9 monthly insurance premium will be added.',
    corpCode: 'CAKP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C972',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C523',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJGP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJGP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQS',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFQS',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1101,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1102,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1103,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1105,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1107,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1108,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1110,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1116,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1118,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1122,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1123,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1124,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1125,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1126,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 1127,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2101,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2102,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2103,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2104,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2105,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2107,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2108,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2110,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2111,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 2112,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 3102,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 3103,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 3104,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 3106,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 3107,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 5102,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 5104,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 5105,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 6102,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 6103,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 6104,
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYY',
    locCode: 'LTRAIN',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LA1A',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LAFF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LALL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LBIG',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LCOL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LFAL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LFAR',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LFKF',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LFSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LLDY',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LLEE',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LUPM',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LWAL',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LWOD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CYAC',
    locCode: 'LWSS',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBVH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZBF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEHQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included in our rent charges. We recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTDN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCFF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCFF',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCFF',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCTJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSHQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSHQ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with all of our storage rentals. Please select a coverage plan to complete your move-in.',
    corpCode: 'CEXU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with all of our storage rentals. Please select a coverage plan to complete your move-in.',
    corpCode: 'CEXU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is required with all of our storage rentals. Please select a coverage plan to complete your move-in.',
    corpCode: 'CEXU',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEXU',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNH',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPNH',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJGQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CTEB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEYI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSLU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSLU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAYI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRZA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CRZA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CSER',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNLN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAE',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAE',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAE',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAE',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBB',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHBB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CWZM',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFVV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CADD',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C292',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C292',
    locCode: 'L064',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C292',
    locCode: 'L126',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C655',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDZ',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C849',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDQE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPYE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFFT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEGC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZMM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHWB',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWO',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCNX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C628',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPPY',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPPY',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBHB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCRC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBWT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CESU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L015',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L016',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L018',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L020',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L024',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L025',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L028',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L029',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L030',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBAA',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CENC',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEEA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEEA',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEEA',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCMJ',
    locCode: 'L006',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFRJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAWM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAKR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHYZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAMX',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCWM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEDW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description: 'Insurance is required.',
    corpCode: 'CZQB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPKW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPKW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPKW',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBFX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L007',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L008',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L011',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCZP',
    locCode: 'L012',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGBI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGBI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAXN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CALD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAVR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C832',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNFM',
    locCode: 'L009',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNFM',
    locCode: 'L010',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGFA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYG',
    locCode: 'GFRD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYG',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDYG',
    locCode: 'RSRD',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBB',
    locCode: 'L001',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L001',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L002',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L003',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L004',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L005',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L007',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L008',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L009',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L010',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L011',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L012',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L013',
  },
  {
    name: 'Tenant Property Protection',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDAA',
    locCode: 'L014',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCDV',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPGC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPGC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBUH',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBUH',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEER',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFKW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKI',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKI',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEKI',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHJ',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFUU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXN',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXN',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXN',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZMV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZVM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included in our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CARU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJGD',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQCX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAU',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAU',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CUAU',
    locCode: 'L005',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQCA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZFZ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYE',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZER',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJFF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFWV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHR',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHR',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDDB',
    locCode: 'L017',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZKM',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGBU',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGBU',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CPXP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CNJA',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CABJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYF',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBC',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJBC',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CDWH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCQP',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCQP',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCQP',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CCQP',
    locCode: 'L004',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHS',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C424',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C424',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'C424',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFUW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CJHT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CZLJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGFL',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFXX',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      "Insurance is not included with our rent charges. Insurance is required. You can purchase or send us a declaration page for your homeowners or renter's policy.",
    corpCode: 'CQCH',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYJ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYJ',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYJ',
    locCode: 'L003',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAYW',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CAYW',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHRT',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CHRT',
    locCode: 'L002',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGFQ',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFYK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGEG',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CQAB',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CEUK',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CFZI',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CGFV',
    locCode: 'L001',
  },
  {
    name: 'Protection Plan',
    description:
      'Insurance is not included with our rent charges. We highly recommend that all tenants insure their goods for peace of mind.',
    corpCode: 'CBHY',
    locCode: 'L031',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'AKRO',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'BROW',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'CHAM',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'COLU',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'DUPO',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'EPHR',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'EXET',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'GETT',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'GREE',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'HILL',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'JUNC',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'KWIK',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'LAKE',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'LANE',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'LITT',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'LSETUP',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'LUFK',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'MANH',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'MOR2',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'MOR7',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'MORJ',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'MURF',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'NAZA',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'NORF',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'ROUT',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'SUSQ',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'SWAN',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'WALD',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'WALK',
  },
  {
    name: 'Protection Plan',
    description:
      'Freedom Storage requires insurance or protection plan coverage on your rental. For private insurance, you have 48 hours to provide a copy of your insurance declaration page or we will have to enroll you into the Storage Shield Protection plan program at the recommended level. You will receive instructions on how to provide proof of coverage once your move-in is confirmed.',
    corpCode: 'C930',
    locCode: 'WAYN',
  },
];

export default insurances;
