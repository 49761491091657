import React from 'react';
import validator from 'validator';
import useUnitData from '../hooks/UseUnitData';
import { useConfig } from '../contexts/ConfigProvider';
import useCostData from '../hooks/UseCostData';
import { createRental } from '../modules/api';
import ContactUpdate from './ContactUpdate';
import { SET_IS_SUBMITTED } from '../components/MoveIn/reducer';
import { getMoveInDate } from '../modules/utils';

const propTypes = {};
const defaultProps = {};

const Submit = () => {
  const { data: {
    facility, reservation: { id: reservationId, contactId }, unit,
  } } = useUnitData();
  const { config: { params } } = useConfig();
  const { data: { dueToday, charges, totalTaxes } } = useCostData();
  const { timezone, geo, invoicePeriod, invoiceDay } = facility;

  const toBase64 = (file) => new Promise((resolve, reject) => {
    if (!file) {
      resolve();
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });

  const onSubmit = async ({ formValues, dispatch, onStepChange }) => {
    let ccType = ['mastercard', 'amex', 'visa', 'discover'].find((provider) => validator.isCreditCard(formValues.ccNumber, { provider }));

    const providerId = facility.alias.split(':')[2];

    if (providerId === 'se' && ccType === 'amex') {
      ccType = 'american_express';
    }
    const [organizationId, facilityId] = facility.alias.split(':')[3].split(';');
    const image = await toBase64(formValues.dlImage[0]);

    const body = {
      contact: {
        driversLicense: formValues.driversLicense,
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        mailing: formValues.mailing,
        militaryStatus: formValues.militaryStatus,
        phone: formValues.phone,
        image,
      },
      password: formValues.password,
      billing: formValues.billing,
      ccNumber: formValues.ccNumber.replace(/\s/g, ''),
      ccExp: formValues.ccExp,
      ccCSC: formValues.ccCSC,
      saveCreditCard: formValues.saveCreditCard,
      autoPayEnabled: formValues.enrollAutoPay,
      moveInDate: getMoveInDate(formValues.moveInDate, timezone, geo),
      insurance: formValues.insurance,
      payment: {
        amount: dueToday,
      },
      ccType,
      // update once we have discounts from backend
      concessionId: -999,
      additionalContacts: formValues.contacts || [],
      contactId,
      providerId,
      organizationId,
      facilityId,
      emailInfo: {
        payments: { dueToday, charges, totalTaxes },
        facility: {
          address: facility.address,
          displayName: facility.displayName,
          emailAddress: facility.emailAddress,
          phoneNumber: facility.phoneNumber,
        },
        unit: {
          amenities: unit.amenities,
          dimensions: unit.dimensions,
          name: unit.name,
          rates: unit.rates,
        },
      },
      returnUrl: window.location.href,
      hasVehicle: formValues.hasVehicle === 'yes',
      military: formValues.militaryStatus === 'yes',
      invoicePeriod,
      invoiceDay,
    };

    if (params.source) {
      body.source = params.source;
    }
    if (params.config) {
      body.configName = params.config;
    }

    if (formValues.hasVehicle === 'yes') {
      // for siteLink
      if (providerId === 'sl') {
        // eslint-disable-next-line prefer-destructuring
        body.vehicle = formValues.vehicles[0];
      }
      // for storEDGE
      if (providerId === 'se') {
        body.storageItems = formValues.vehicles;
      }
    }

    if (formValues.lienholders[0]?.name && formValues.lienholders[0]?.address1
      && formValues.lienholders[0]?.city && formValues.lienholders[0]?.state
      && formValues.lienholders[0]?.zipCode) {
      body.lienHolders = body.lienHolders || {};
      body.lienHolders = formValues.lienholders;
    }

    if ((formValues.securityQuestionOne && formValues.securityAnswerOne)
      && (formValues.securityQuestionTwo && formValues.securityAnswerTwo)) {
      body.contact = body.contact || {};

      body.contact.passwordRecoveryQuestions = [
        {
          question: formValues.securityQuestionOne,
          answer: formValues.securityAnswerOne,
        },
        {
          question: formValues.securityQuestionTwo,
          answer: formValues.securityAnswerTwo,
        },
      ];
    }

    createRental({ reservationId, body })
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          const errors = res.details?.map((details) => details.errors).flat() || [res.error];
          dispatch({
            type: SET_IS_SUBMITTED,
            payload: { success: false, errors },
          });
          window.storableOmi?.onError?.({ action: 'submission', errors: errors.map((error) => ({ name: error })) });
        } else if (res.rental?.error) {
          dispatch({
            type: SET_IS_SUBMITTED,
            payload: { success: false, errors: [res.rental.message] },
          });
          window.storableOmi?.onError?.({ action: 'submission', errors: [{ name: res.rental.message }] });
        } else {
          dispatch({
            type: SET_IS_SUBMITTED,
            payload: {
              success: true,
              leaseUrl: res.rental.leaseUrl,
              rentalAlias: res.rental.rental.alias,
              rentalId: res.rental.rental.id,
              gateAccessCode: res.rental.rental.gateAccessCode,
            },
          });
          onStepChange?.();
        }
      })
      .catch((e) => {
        dispatch({
          type: SET_IS_SUBMITTED,
          payload: { success: false, errors: [e.message] },
        });
        window.storableOmi?.onError?.({ action: 'submission', errors: [{ name: e.message }] });
      });
  };

  return <ContactUpdate onSubmit={onSubmit} />;
};

Submit.propTypes = propTypes;
Submit.defaultProps = defaultProps;

export default Submit;
