/* eslint-disable import/prefer-default-export */
export const containsProperty = (obj, propertyName) => propertyName in obj;

export const formatMoveInDate = (date = new Date()) => {
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return `${mm > 9 ? '' : '0'}${mm}-${
    dd > 9 ? '' : '0'
  }${dd}-${date.getFullYear()}`;
};

export const getMoveInDate = (date, timezone, geo) => {
  if (!geo?.invalidData && timezone?.identifier) {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    const currentSeconds = now.getSeconds();
    const currentMilliseconds = now.getMilliseconds();

    const localDate = new Date(date);

    localDate.setHours(currentHours, currentMinutes, currentSeconds, currentMilliseconds);

    const timeZoneIdentifier = timezone?.identifier;
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timeZoneIdentifier,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(localDate);

    const formattedDate = formatter.reduce((acc, part) => {
      if (part.type === 'year') { acc.year = part.value; }
      if (part.type === 'month') { acc.month = part.value; }
      if (part.type === 'day') { acc.day = part.value; }
      return acc;
    }, {});

    const moveInDate = `${formattedDate.month}-${formattedDate.day}-${formattedDate.year}`;
    return moveInDate;
  }
  return formatMoveInDate(date);
};

export const parseJson = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

export const isValidTimezone = (tz) => {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (e) {
    return false;
  }
};
export const getFormattedDateRange = (timezoneIdentifier) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tz = isValidTimezone(timezoneIdentifier) ? timezoneIdentifier : userTimezone;
  const now = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));
  const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const start = currentDate.toLocaleDateString('en-US', options);
  const end = lastDayOfMonth.toLocaleDateString('en-US', options);

  return `(${start} - ${end})`;
};

export const getNextMonthDateRange = (timezoneIdentifier) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tz = timezoneIdentifier || userTimezone;

  const now = new Date(new Date().toLocaleString('en-US', { timeZone: tz }));

  const firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const lastDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const start = formatter.format(firstDayOfNextMonth);
  const end = formatter.format(lastDayOfNextMonth);

  return `(${start} - ${end})`;
};

export const checkServicePeriod = (day = 0) => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysRemaining = lastDayOfMonth.getDate() - today.getDate();
  return daysRemaining < day;
};

const lockRegex = /\b(lock|locks|padlock|padlocks|[a-z]+-lock|[a-z]+-locks)\b/i;
const unitRegex = /\b(unit|units|padunit|padunits|[a-z]+-unit|[a-z]+-units)\b/i;

export const showDescription = (charge, showNextMonthPayment, timezone) => {
  let chargeDescription = charge.description;
  if (showNextMonthPayment
    && !charge.prorated
    && !lockRegex.test(chargeDescription)
    && unitRegex.test(chargeDescription)) {
    chargeDescription = ` Second Month’s Rent ${getNextMonthDateRange(timezone?.identifier)}`;
    // Unit second month price
  } else if (showNextMonthPayment
    && charge.prorated
    && !lockRegex.test(chargeDescription)
    && unitRegex.test(chargeDescription)) {
    chargeDescription = ` Prorated First Month’s Rent ${getFormattedDateRange(timezone?.identifier)}`;
    // Unit first month price
  }
  return chargeDescription;
};
