import React, { useEffect } from 'react';
import { Link, Box, Text, Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { sizes } from '../../theme';
import useUnitData from '../../hooks/UseUnitData';
import { useConfig } from '../../contexts/ConfigProvider';
import FormSelect from '../FormSelect';
import FormCheckbox from '../FormCheckbox';
import { makeFieldsRequired } from '../MoveIn/reducer';

const propTypes = {};
const defaultProps = {};

const InsuranceSelector = ({ step }) => {
  const { getValues } = useFormContext();

  const { insurance } = getValues();
  const {
    data: {
      facility: { insuranceOptions, insuranceSettings, alias, isInsuranceRequired },
    },
  } = useUnitData();

  const { configuration } = useConfig();
  const providerId = alias.split(':')[2];
  const insuranceRequired = providerId === 'se'
    ? isInsuranceRequired
    : insuranceSettings.required || configuration?.insurance?.allowTenantsToBuyInsurance;

  const isAutoProtectEnabled = insurance === 'private'
    && providerId === 'se'
    && insuranceOptions?.some((option) => option.id === insurance && option.autoProtect);

  const selectedInsurance = insuranceOptions.find(
    (option) => option.id.toString() === insurance.toString(),
  );
  useEffect(() => {
    if (isAutoProtectEnabled) {
      makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: true }, step });
    } else {
      makeFieldsRequired({ stepModifiers: { confirmPrivateInsuranceRequired: false }, step });
    }
  }, [isAutoProtectEnabled, insurance]);

  return (
    <Box>
      <FormSelect
        title={
          configuration.insurance.name
            ? `Select Your ${configuration.insurance.name}`
            : insuranceSettings.header
        }
        tooltip="Your property will not be insured by the self-storage facility you are renting at. Most storage facilities require insurance on stored property."
        description={configuration.insurance.description || insuranceSettings.description}
        name="insurance"
        label={configuration.insurance.name || insuranceSettings.name}
        options={insuranceOptions}
        required={insuranceRequired || false}
      />

      {selectedInsurance?.brochureUrl && (
        <Box mt={sizes._12px} mb={sizes._12px}>
          <Text as="span" variant="smBold">
            Review insurance documents here {' : '}
            <Link
              href={selectedInsurance.brochureUrl.startsWith('http') ? selectedInsurance.brochureUrl : `http://${selectedInsurance.brochureUrl}`}
              isExternal
              textDecoration="underline"
              sx={{ color: 'primary.accent !important' }}
            >
              Brochure Link
            </Link>
          </Text>
        </Box>
      )}

      {isAutoProtectEnabled && (
        <Flex align="flex-start" gap="8px">
          <Box>
            <FormCheckbox
              name="confirmPrivateInsurance"
            />
          </Box>
          <Text>
            Our rental agreement requires all occupants to maintain insurance coverage for
            loss and damage to their stored property. By choosing the private insurance
            option above, you agree to provide proof of insurance coverage within thirty (30)
            days. If you do not provide such proof, you will be automatically enrolled in the
            third-party insurance plan offered above.
          </Text>
        </Flex>
      )}
    </Box>
  );
};

InsuranceSelector.defaultProps = defaultProps;
InsuranceSelector.propTypes = propTypes;

export default InsuranceSelector;
