import * as yup from 'yup';
import { parse, isDate } from 'date-fns';
import { validateCC, validateAge } from '../../modules/validators';

// const date = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;
const zip = /^[0-9]{5}(?:-[0-9]{4})?$/;
const phone = /\(\d{3}\)\s\d{3}-\d{4}/;
const email = /^(?!\.)(?!.*\.{2})([a-zA-Z0-9._+-]+(?<!\.))@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'MM/dd/yyyy', new Date());

  return parsedDate;
}

const getSchema = ({ cardsAccepted, formConfig, insuranceName, providerId }) => yup
  .object().shape({
    // Step 1: Personal Information
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .matches(email, 'Invalid email address')
      .required('Email is required'),
    phone: yup
      .string()
      .matches(phone, { message: 'Invalid phone number' })
      .required('Phone number is required'),
    moveInDate: yup
      .date()
      .transform(parseDateString)
      .typeError('Should be a valid Date')
      .required('Move-in date is required'),

    // Step 2: Additional Information
    insuranceRequired: yup.boolean(),
    insurance: yup.string().when('insuranceRequired', {
      is: true,
      then: (schema) => schema.required(`${insuranceName} cannot be blank`),
    }),
    showLock: yup.boolean(),
    lock: yup.string().when('showLock', {
      is: true,
      then: (schema) => schema.required('Lock selection cannot be blank'),
    }),
    driversLicense: yup.object().shape({
      number: yup.string().required('Drivers license number is required'),
      state: yup.string().required('State is required'),
      expirationDate: yup
        .date()
        .transform(parseDateString)
        .typeError('Should be a valid Date')
        .required('Expiration date is required')
        .test(
          'is-valid-exp',
          'Expiration date must be after today',
          (value) => new Date(value) > new Date(),
        ),
      birthDate: yup
        .date()
        .transform(parseDateString)
        .typeError('Should be a valid Date')
        .required('Birth date is required')
        .test(
          'is-valid-exp',
          'Primary tenant must be at least 18 years old',
          (value) => validateAge(value),
        ),
    }),
    dlImage: yup.mixed().test(
      'fileSize',
      ({ value }) => {
        const size = (value[0].size / 1000000).toFixed(2);
        const maxSize = providerId === 'se' ? 10 : 100;
        return `The file you attempted to upload is ${size} MB.  Files must be less than ${maxSize}MB.  Please reduce your file size and try again.`;
      },
      (value) => {
        if (!value?.length) {
          return true;
        }
        const maxSize = providerId === 'se' ? 10000000 : 100000000;
        return value[0].size <= maxSize;
      },
    ),
    mailing: yup.object().shape({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      addressOne: yup.string().required('Address is required'),
      addressTwo: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      zipCode: yup
        .string()
        .matches(zip, { message: 'Zip code is invalid' })
        .required('Zip code is required'),
    }),
    hasVehicle: yup.string().required(),
    militaryStatus: yup.string().required(),
    vehicles: yup
      .array()
      .of(
        yup.object({
          description: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('Description is required')
              : schema.notRequired())),
          make: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('Make is required')
              : schema.notRequired())),
          model: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('Model is required')
              : schema.notRequired())),
          VIN: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('VIN is required')
              : schema.notRequired())),
          state: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('State is required')
              : schema.notRequired())),
          license: yup
            .string()
            .when('hasVehicle', (hasVehicle, schema) => (hasVehicle
              ? schema.required('License plate is required')
              : schema.notRequired())),
        }),
      )
      .max(formConfig.vehicleCount),
    contacts: yup
      .array()
      .of(
        yup.object({
          firstName: yup.string(),
          lastName: yup.string(),
          relationship: yup.string(),
          phone: yup.string().matches(phone, {
            message: 'Invalid phone number',
            excludeEmptyString: true,
          }),
        }),
      )
      .max(formConfig.contactCount),
    lienholders: yup
      .array()
      .of(
        yup.object({
          name: yup.string(),
          address1: yup.string(),
          address2: yup.string(),
          city: yup.string(),
          state: yup.string(),
          zipCode: yup.string().matches(zip, {
            message: 'Zip code is invalid',
            excludeEmptyString: true,
          }),
        }),
      )
      .max(formConfig.lienholderCount),

    // Step 3: Account
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    // https://github.com/jquense/yup?tab=readme-ov-file#schemawhenkeys-string--string-builder-object--values-any-schema--schema-schema
    securityQuestionsRequired: yup.boolean(),
    securityQuestionOne: yup.string().when('securityQuestionsRequired', {
      is: true,
      then: (schema) => schema.required('Security Question 1 is required'),
    }),
    securityAnswerOne: yup.string().when('securityQuestionsRequired', {
      is: true,
      then: (schema) => schema.required('Security Answer 1 is required'),
    }),
    securityQuestionTwo: yup.string().when('securityQuestionsRequired', {
      is: true,
      then: (schema) => schema.required('Security Question 2 is required'),
    }),
    securityAnswerTwo: yup.string().when('securityQuestionsRequired', {
      is: true,
      then: (schema) => schema.required('Security Answer 2 is required'),
    }),

    // Step 4: Payment
    ccNumber: yup
      .string()
      .required('Credit card number is required')
      .test('cc-number', 'Credit card number is invalid', (value) => validateCC(value, cardsAccepted)),
    ccExp: yup
      .string()
      .matches(/^(0[1-9]|1[0-2])\/\d{2}$/g, 'Invalid expiration date')
      .required('Expiration date is required')
      .test(
        'is-valid-exp',
        'Expiration date must be after today',
        (value) => {
          const [month, year] = value.split('/');
          return new Date(`${month}/31/${year}`) > new Date();
        },
      ),
    ccCSC: yup
      .string()
      .min(3, 'Invalid CVV')
      .max(4, 'Invalid CVV')
      .required('CSC is required'),
    billing: yup.object().shape({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      addressOne: yup.string().required('Address is required'),
      addressTwo: yup.string(),
      city: yup.string().required('City is required'),
      state: yup.string().required('State is required'),
      zipCode: yup
        .string()
        .matches(zip, { message: 'Zip code is invalid' })
        .required('Zip code is required'),
    }),
    confirmPrivateInsurance: yup.boolean(),
  })
  .required();

// TODO: Driver's License Image Upload
/*

*/

export default getSchema;
