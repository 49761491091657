import {
  baseSteps,
  DECREMENT_STEP,
  INCREMENT_STEP,
  TOGGLE_T_AND_C_MODAL,
} from '../components/MoveIn/reducer';

export const getOnStepChangePayload = (currentStep, nextStep) => ({
  totalSteps: baseSteps.length,
  navigatingFrom: {
    step: currentStep.number,
    identifier: currentStep.identifier,
  },
  navigatingTo: {
    step: nextStep.number,
    identifier: nextStep.identifier,
  },
});

const onForwardMap = [
  ({ dispatch, getValues, setValue }) => {
    window.storableOmi?.onStepChange?.(getOnStepChangePayload(baseSteps[0], baseSteps[1]));
    const { firstName, lastName } = getValues();
    setValue('mailing.firstName', firstName, { shouldDirty: true });
    setValue('mailing.lastName', lastName, { shouldDirty: true });
    dispatch({ type: INCREMENT_STEP });
  },
  ({ dispatch }) => {
    window.storableOmi?.onStepChange?.(getOnStepChangePayload(baseSteps[1], baseSteps[2]));
    dispatch({ type: INCREMENT_STEP });
  },
  ({ dispatch, isPasswordSubmitted, onUpdateContact }) => {
    if (isPasswordSubmitted) {
      dispatch({ type: INCREMENT_STEP });
      window.storableOmi?.onStepChange?.(getOnStepChangePayload(baseSteps[2], baseSteps[3]));
    } else {
      onUpdateContact({
        dispatch,
        onStepChange: window.storableOmi?.onStepChange?.(
          getOnStepChangePayload(baseSteps[2], baseSteps[3]),
        ),
      });
    }
  },
  ({ dispatch }) => {
    dispatch({ type: TOGGLE_T_AND_C_MODAL, show: true });
  },
];

const addStepChange = () => {
  const steps = baseSteps.map((step, i) => {
    if (step.initial) {
      return { ...step, onForward: onForwardMap[i] };
    }

    const onBack = ({ dispatch }) => {
      dispatch({ type: DECREMENT_STEP });
      window.storableOmi?.onStepChange?.(getOnStepChangePayload(step, steps[step.number - 2]));
    };

    return { ...step, onBack, onForward: onForwardMap[i] };
  });

  return steps;
};

export default addStepChange;
