import React from 'react';
import {
  Alert,
  AlertIcon,
  VStack,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import CardsAccepted from '../CardsAccepted';
import CardInformation from '../CardInformation';
import BillingInformation from '../BillingInformation';
import Terms from '../Terms';
import { sizes } from '../../theme';

const propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ),
  closeTAndCModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showTAndCModal: PropTypes.bool.isRequired,
};

const defaultProps = {
  errors: [],
};

const Step4 = ({ errors, showTAndCModal, closeTAndCModal, onSubmit }) => (
  <>
   <VStack spacing={3} marginBottom={sizes._12px}>
            {errors.map((error) => (
              <Alert status="error" key={error}>
                <AlertIcon />
                {error}
              </Alert>
            ))}
   </VStack>
    <CardsAccepted />
    <CardInformation />
    <BillingInformation />
    <Terms isOpen={showTAndCModal} onClose={closeTAndCModal} onSubmit={onSubmit} />

  </>
);

Step4.propTypes = propTypes;
Step4.defaultProps = defaultProps;

export default Step4;
