import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import FormInput from '../FormInput';
import StateSelector from '../StateSelector';
import { sizes } from '../../theme';

const propTypes = {
  index: PropTypes.number,
  hasVehicles: PropTypes.bool.isRequired,
};
const defaultProps = {
  index: undefined,
};

const name = 'vehicles';

const VehicleForm = ({ hasVehicles, index }) => (
    <>
      <Flex gap={sizes._8px}>
        <Box flex="1">
          <FormInput
            name={`${name}.${index}.make`}
            label="Make"
            rules={hasVehicles ? { required: 'Make is required' } : {}}
          />
        </Box>
        <Box flex="1">
          <FormInput
            name={`${name}.${index}.model`}
            label="Model"
            rules={hasVehicles ? { required: 'Model is required' } : {}}
          />
        </Box>
      </Flex>
      <Flex gap={sizes._8px}>
        <Box flex="1">
          <FormInput
            name={`${name}.${index}.license`}
            label="License Plate"
            rules={hasVehicles ? { required: 'License Plate is required' } : {}}
          />
        </Box>
        <Box flex="1">
          <StateSelector
            name={`${name}.${index}.state`}
            required={hasVehicles}
          />
        </Box>
      </Flex>
      <FormInput
        name={`${name}.${index}.VIN`}
        label="VIN"
        rules={hasVehicles ? { required: 'VIN is required' } : {}}
      />
      <FormInput
        name={`${name}.${index}.description`}
        label="Description"
        rules={hasVehicles ? { required: 'Description is required' } : {}}
      />
    </>
);

VehicleForm.propTypes = propTypes;
VehicleForm.defaultProps = defaultProps;

export default VehicleForm;
