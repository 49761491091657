import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { AccessIcon, CalendarIcon, ContactIcon, FacilityIcon, UnitIcon } from '../Icons';
import useUnitData from '../../hooks/UseUnitData';
import { sizes } from '../../theme';
import { useConfig } from '../../contexts/ConfigProvider';
import { sendLeaseConfirmationMail } from '../../modules/api';

const propTypes = {
  gateAccessCode: PropTypes.string,
};
const defaultProps = {
  gateAccessCode: undefined,
};

const Complete = ({ gateAccessCode }) => {
  const { getValues } = useFormContext();

  const {
    password,
    moveInDate,
    email,
  } = getValues();
  const {
    configuration,
    config: { providerId, params },
  } = useConfig();
  const {
    data: {
      unit: { name, size, rates: { effectiveRate } },
      facility: { address, displayName, phoneNumber, alias },
      reservation:
      {
        contactId,
        rentalAlias,
        rentalId,
        desiredMoveInDate,
        contact: {
          emailAddress,
          firstName,
          lastName,
          phoneNumber: contactPhoneNumber,
        },
      },
    },
  } = useUnitData();

  const companyId = alias.split(':')[3].split(';')[0];

  useEffect(() => {
    if (providerId.toLowerCase() === 'sl') {
      sendLeaseConfirmationMail({
        rentalAlias,
        rentalId,
        companyId,
        configName: params.config,
        source: params.source,
        contactId,
      });
    }
  }, []);

  const date = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const gateCodeDisplay = configuration?.accessControl?.showGateCodeAtEnd ? gateAccessCode : 'Please stop at the front desk during business hours to receive your access code.';
  return (
    <>
      <Box sx={{ borderTopColor: 'gray.lightBorder !important' }} borderTop="4px solid" py={sizes._16px} mx={sizes._12px}>
        <Flex align="center">
          <Text variant="xlBold">
            Your Rental Is Complete!
          </Text>
        </Flex>
      </Box>
      <Box sx={{ borderTopColor: 'gray.lightBorder !important' }} borderTop="4px solid" py={sizes._16px} mx={sizes._12px}>
        <Text variant="lgBold" py={sizes._8px}>
          Next Steps
        </Text>
        <UnorderedList>
          <ListItem>
            <Text variant="lg" py={sizes._8px}>
              Contact the facility before your move-in date if you have questions
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="lg" py={sizes._8px}>
              Use the facility access code to enter the facility and find your unit
            </Text>
          </ListItem>
          <ListItem>
            <Text variant="lg" py={sizes._8px}>
              Ensure to make your monthly payment to the
              facility to continue your storage rental
            </Text>
          </ListItem>
        </UnorderedList>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <CalendarIcon color="primary.accent" w="49px" h="49px" />
        </Box>
        <Box display="inline-block" verticalAlign="middle">
          <Text variant="mdBold">Move-In Date</Text>
          <Text variant="lg" color="gray.lightText">
            {password
              ? date.format(new Date(moveInDate))
              : date.format(new Date(desiredMoveInDate))}
          </Text>
        </Box>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <UnitIcon color="primary.accent" w="49px" h="49px" />
        </Box>
        <Box display="inline-block" verticalAlign="middle">
          <Text variant="mdBold">Unit Details</Text>
          <Text variant="lg" color="gray.lightText">
            {size} - #{name}
          </Text>
          <Text
            variant="mdBold"
            sx={{ whiteSpace: 'pre', display: 'inline-block' }}
          >
            {`$${effectiveRate} `}
          </Text>
          <Text variant="sm" sx={{ whiteSpace: 'pre', display: 'inline-block' }}>
            Monthly Rent
          </Text>
        </Box>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="flex" alignItems="center">
          <AccessIcon color="primary.accent" w="49px" h="49px" />
          <Box ml={sizes._12px}>
            <Text variant="mdBold">Access Code</Text>
            <Text variant="md" color="gray.lightText">
              {gateCodeDisplay}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <ContactIcon color="primary.accent" w="49px" h="49px" />
        </Box>
        <Box display="inline-block" verticalAlign="middle">
          <Text variant="mdBold">Contact Information</Text>
          <Text variant="md" color="gray.lightText">
            {`${firstName} ${lastName}`}
          </Text>
          <Text variant="md" color="gray.lightText">
            {email || emailAddress}
          </Text>
          <Text variant="md" color="gray.lightText">
            {contactPhoneNumber}
          </Text>
        </Box>
      </Box>
      <Box mx={sizes._12px} py={sizes._12px} borderTop="4px solid" borderTopColor="gray.lightBorder">
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <FacilityIcon color="primary.accent" w="138px" h="62px" marginTop={sizes._16px} />
        </Box>
        <Box display="inline-block" verticalAlign="middle" mr={sizes._12px}>
          <Text variant="mdBold">Facility Details</Text>
          <Text variant="header">{displayName}</Text>
          <Text variant="md">{address.streetAddress1}</Text>
          {address.streetAddress2.length > 0 && (
            <Text variant="md">{address.streetAddress2}</Text>
          )}
          <Text variant="md">
            {`${address.city}, ${address.region} ${address.postalCode}`}
          </Text>
          <Text variant="md">{phoneNumber}</Text>
        </Box>
      </Box>
    </>
  );
};

Complete.propTypes = propTypes;
Complete.defaultProps = defaultProps;

export default Complete;
